// Type declaration for Alpine.js
declare global {
  interface Window {
    Alpine: any;
  }
}

import Alpine from "alpinejs";

export function install() {
  // Initialize Alpine.js
  window.Alpine = Alpine;

  // Register the accordion component
  Alpine.data("accordion", (defaultOpen: number | null = null) => ({
    selected: defaultOpen as number | null,

    toggle(id: number) {
      this.selected = this.selected === id ? null : id;
    },

    isOpen(id: number) {
      return this.selected === id;
    },

    // This matches the CodePen behavior
    getMaxHeight(el: HTMLElement) {
      if (this.isOpen(parseInt(el.id || "0"))) {
        return `${el.scrollHeight}px`;
      }
      return "0px";
    },
  }));

  // Start Alpine
  Alpine.start();
}
